<script>
  export default {
    data() {
      return {}
    },
  }
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
  body {
    background: #bcc3c9;
    margin: 0px;
    padding: 0px;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
